@import "variables";

// Converted Variables
$width: 245px;

// Custom Media Query Variables

.navbar .steps {
  width: 687px;
  margin-left: calc(50% - 315px);
  padding-top: 20px;
  float: left;
}
.navbar .steps ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.navbar .steps ul li {
  width: 110px;
  margin: 0 12px;
  text-align: center;
  float: left;
  position: relative;
}
.navbar .steps ul li a {
  text-decoration: none;
}
.navbar .steps ul li:after {
  width: 80px;
  height: 1px;
  content: '';
  background: #ad906e;
  top: 9px;
  left: -50%;
  position: absolute;
}
.navbar .steps ul li:first-child:after {
  display: none;
}
.navbar .steps ul li .number {
  width: 20px;
  height: 20px;
  line-height: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin: 0 auto 5px;
  background: #fff;
  text-align: center;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #fff;
  display: block;
  position: relative;
}
.navbar .steps ul li .number:after {
  width: 5px;
  height: 5px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin: -2px 0 0 -2px;
  background: #ad906e;
  content: '';
  top: 50%;
  left: 50%;
  position: absolute;
}
.navbar .steps ul li.checked .number {
  background: #775ca7;
}
.navbar .steps ul li.checked .number:after {
  display: none;
}
.navbar .steps ul li .text {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 11px;
  color: #ad906e;
  text-transform: uppercase;
  display: block;
}
.navbar .steps ul li.checked .text {
  color: #775ca7;
}
.navbar {
  .steps {
    li.step-selected {
      .text {
        color: $gold;
      }
      .number {
        background-color: $purple;

        &::after {
          display: none;
        }
      }
    }
  }
}
#carrinho-vazio {
  background: #f1e8e0;
  min-height: 565px;
}
#carrinho-vazio .center-shop {
  max-width: 950px;
  margin: auto;
}
#carrinho-vazio .center-shop .shopping-top {
  height: 100px;
}
#carrinho-vazio .center-shop .shopping-top p {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 20px;
  color: #4f2c1e;
  text-transform: uppercase;
  line-height: 100px;
  margin: 0;
}
#carrinho-vazio .center-shop .navegate {
  padding: 20px 0;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #775ca7;
  text-decoration: underline;
  text-transform: uppercase;
}
#carrinho-vazio .center-shop .empty {
  padding: 60px 0;
  background: #ded5cd;
  text-align: center;
  clear: both;
}
#carrinho-vazio .center-shop .empty img {
  margin: 0 auto 10px;
}
#carrinho-vazio .center-shop .empty .line-top {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
  text-transform: uppercase;
}
#carrinho-vazio .center-shop .empty p,
#carrinho-vazio .center-shop .empty a {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
#carrinho-vazio .center-shop .empty a {
  text-decoration: underline;
}
@media (min-width: 992px) and (max-width: 1260px) {
  #carrinho-vazio {
    background: #fff;
  }
}
@media (max-width: 991px) {
  #carrinho-vazio {
    min-height: 0;
    margin-bottom: 50px;
  }
  #carrinho-vazio .shopping-top h2 {
    text-align: center;
    padding: 25px 0 23px;
  }
  #carrinho-vazio .center-shop .empty {
    padding: 30px 20px;
  }
  #carrinho-vazio .center-shop .empty p,
  #carrinho-vazio .center-shop .empty a {
    font-size: 16px;
  }
  #carrinho-vazio .center-shop .empty .line-top {
    font-size: 17px;
    line-height: 23px;
    padding: 10px 20px 0;
  }
}
#cesta-de-compras {
  background: #f1e8e0;
}
#cesta-de-compras .center-shop {
  width: 950px;
  margin: auto;
  background: #f1e8e0;
}
#cesta-de-compras .center-shop .shopping-top {
  height: 100px;
}
#cesta-de-compras .center-shop .shopping-top p {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 20px;
  color: #4f2c1e;
  text-transform: uppercase;
  line-height: 100px;
  margin: 0;
}
#cesta-de-compras .center-shop .shopping-top a {
  margin-top: 24px;
  background: #775ca7;
  color: #e9d4df;
}
#cesta-de-compras .center-shop .shopping-top a:hover {
  background: #513593;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#cesta-de-compras .center-shop .shopping {
  padding: 0;
  margin: 0;
}
#cesta-de-compras .center-shop .shopping li {
  display: block;
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid #c8b59e;
}
#cesta-de-compras .center-shop .shopping li:first-child {
  padding: 5px 0;
  border-bottom: 2px solid #c8b59e;
}
#cesta-de-compras .center-shop .shopping li div {
  display: inline-block;
}
#cesta-de-compras .center-shop .shopping li div .title {
  text-transform: uppercase;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
#cesta-de-compras .center-shop .shopping li .product-shop {
  width: 400px;
  margin: 0 28px 0 0;
}
#cesta-de-compras .center-shop .shopping li .product-shop .title {
  margin-left: 10px;
}
#cesta-de-compras .center-shop .shopping li .product-shop .img {
  width: 161px;
}
#cesta-de-compras .center-shop .shopping li .product-shop .img img {
  width: 161px;
}
#cesta-de-compras .center-shop .shopping li .product-shop .text {
  margin-left: 20px;
  width: 215px;
}
#cesta-de-compras .center-shop .shopping li .product-shop .text .name {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #85685b;
  margin-bottom: 2px;
}
#cesta-de-compras .center-shop .shopping li .product-shop .text .sub-name {
  font-family: 'martelheavy';
  font-weight: 600;
  font-size: 12px;
  color: #4f2c1e;
  margin-bottom: 2px;
}
#cesta-de-compras .center-shop .shopping li .product-shop .text .description-title {
  text-transform: uppercase;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #4f2c1e;
  margin-bottom: 2px;
}
#cesta-de-compras .center-shop .shopping li .product-shop .text .description {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #85685b;
  line-height: 12px;
  margin-bottom: 2px;
}
#cesta-de-compras .center-shop .shopping li .amount-shop {
  width: 138px;
  margin: 0 28px;
  vertical-align: middle;
}
#cesta-de-compras .center-shop .shopping li .amount-shop .title {
  text-align: center;
}
#cesta-de-compras .center-shop .shopping li .amount-shop div {
  display: block;
  text-align: center;
  margin: auto;
}
#cesta-de-compras .center-shop .shopping li .amount-shop div .mais,
#cesta-de-compras .center-shop .shopping li .amount-shop div .menos {
  background: #c8b59e;
  height: 35px;
  width: 35px;
  font-size: 20px;
  color: #fff;
  border: 0;
  padding-top: 5px;
  text-align: center;
  outline: none;
  vertical-align: middle;
}
#cesta-de-compras .center-shop .shopping li .amount-shop div .quantidade {
  height: 35px;
  width: 40px;
  font-size: 20px;
  background: #fff;
  border: 0;
  cursor: default;
  text-align: center;
  padding: 0 5px;
  vertical-align: middle;
}
#cesta-de-compras .center-shop .shopping li .amount-shop div a {
  margin-top: 10px;
  display: block;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
  text-decoration: underline;
}
#cesta-de-compras .center-shop .shopping li .price-shop {
  width: 118px;
  margin: 0 28px;
  text-align: center;
}
#cesta-de-compras .center-shop .shopping li .price-shop p {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
}
#cesta-de-compras .center-shop .shopping li .total-shop {
  width: 110px;
  margin: 0 0px 0 28px;
}
#cesta-de-compras .center-shop .shopping li .total-shop .title {
  text-align: right;
}
#cesta-de-compras .center-shop .shopping li .total-shop p {
  text-align: right;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
}
#cesta-de-compras .center-shop .freight-calculate {
  padding: 20px 0 15px 0;
}
#cesta-de-compras .center-shop .freight-calculate div {
  display: inline-block;
  vertical-align: middle;
}
#cesta-de-compras .center-shop .freight-calculate .calculate div {
  display: inline-block;
  vertical-align: middle;
  margin: 0 30px 0 0px;
}
#cesta-de-compras .center-shop .freight-calculate .calculate div:first-child {
  margin: 0 10px 0 0;
}
#cesta-de-compras .center-shop .freight-calculate .calculate div p {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #4f2c1e;
  text-transform: uppercase;
  margin: 0;
  text-align: right;
}
#cesta-de-compras .center-shop .freight-calculate .calculate div .freight-cep {
  display: block;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #4f2c1e;
}
#cesta-de-compras .center-shop .freight-calculate .calculate div input {
  width: 115px;
  height: 40px;
  padding: 0 20px;
}
#cesta-de-compras .center-shop .freight-calculate .calculate a {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #775ca7;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: middle;
  text-decoration: underline;
}
#cesta-de-compras .center-shop .freight-calculate .calculate .description {
  display: block;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 8px;
  color: #4f2c1e;
  margin: 20px 0 0 30px;
  strong {

    font-family: 'martelheavy';
    font-size: 9px;
  }
}
#cesta-de-compras .center-shop .freight-calculate .freight {
  height: auto;
  width: 300px;
  margin-bottom: 1rem;
  padding: 0;
}
#cesta-de-compras .center-shop .freight-calculate .freight .text {
  float: left;
}
#cesta-de-compras .center-shop .freight-calculate .freight p {
  display: inline-block;
  vertical-align: top;
  text-transform: uppercase;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
  margin: 0px 0 0 0;
}
#cesta-de-compras .center-shop .freight-calculate .freight .number {
  float: right;
}
#cesta-de-compras .center-shop .freight-value {
  width: 110px;
  margin: 0 0px 0 34px;
}
#cesta-de-compras .center-shop .freight-value p {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
  text-align: right;
  margin: -24px 0 0 0;
}
#cesta-de-compras .align-border-shop {
  border-bottom: 1px solid #c8b59e;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 108px;
}
#cesta-de-compras .align-border-shop .center-shop {
  background: #fff !important;
}
#cesta-de-compras .align-border-shop .center-shop div {
  display: inline-block;
  vertical-align: top;
}
#cesta-de-compras .align-border-shop .center-shop .keep-buy {
  max-width: 220px;
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
#cesta-de-compras .align-border-shop .center-shop .keep-buy a {
  background-color: $brown-light;
  color: $brown-dark;
  border-color: $brown-dark;
  display: block;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 1em;
  text-transform: uppercase;
}
#cesta-de-compras .align-border-shop .center-shop .voucher {
  margin-right: 40px;
}
#cesta-de-compras .align-border-shop .center-shop .voucher .code p {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #4f2c1e;
  text-transform: uppercase;
  margin: 0;
}
#cesta-de-compras .align-border-shop .center-shop .voucher .code span {
  display: block;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #4f2c1e;
  text-align: right;
}
#cesta-de-compras .align-border-shop .center-shop .voucher .form-group {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}
#cesta-de-compras .align-border-shop .center-shop .voucher div {
  vertical-align: middle;
  display: inline-block;
}
#cesta-de-compras .align-border-shop .center-shop .voucher div a {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #775ca7;
  text-transform: uppercase;
  text-decoration: underline;
  display: block;
  cursor: pointer;
  margin: 0 0 0 30px;
}
#cesta-de-compras .align-border-shop .center-shop .desconto {
  border: 2px solid #775ca7;
  padding: 20px 20px;
  color: #775ca7;
  text-align: center;
  margin-right: 93px;
  display: none;
}
#cesta-de-compras .align-border-shop .center-shop .desconto span.titulo {
  font-size: 12px;
}
#cesta-de-compras .align-border-shop .center-shop .desconto span,
#cesta-de-compras .align-border-shop .center-shop .desconto a {
  font-size: 8px;
  text-transform: uppercase;
}
#cesta-de-compras .align-border-shop .center-shop .desconto p {
  font-size: 12px;
  text-transform: uppercase;
  color: #775ca7;
}
#cesta-de-compras .align-border-shop .center-shop .total {
  width: 119px;
  margin: 10px 0 0 0;
}
#cesta-de-compras .align-border-shop .center-shop .total p {
  text-align: right;
  text-transform: uppercase;
  margin: 0;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
}
#cesta-de-compras .align-border-shop .center-shop .price {
  // width: 133px;
  margin: 10px 0px 20px 34px;
}
#cesta-de-compras .align-border-shop .center-shop .price p {
  font-family: 'martelheavy';
  font-weight: normal;
  font-size: 18px;
  color: #4f2c1e;
  text-align: right;
  margin: 0 0 0 0;
  text-transform: uppercase;
}
#cesta-de-compras .align-border-shop .center-shop .end-buy {
  float: right;
  margin: 1rem 0 0 0;
}
#cesta-de-compras .align-border-shop .center-shop .end-buy .btn {
  display: block;
}
#cesta-de-compras .align-border-shop .center-shop .end-buy span {
  margin-top: 17px;
  display: block;
}

@media (max-width: 991px) {
  #cesta-de-compras {
    background: #fff;
    padding: 0 10px 162px 10px;
    position: relative;
  }
  #cesta-de-compras .shopping-top {
    padding: 50px 0;
    width: 100%;
  }
  #cesta-de-compras .shopping-top h2 {
    text-align: center;
    font-size: 47px;
  }
  #cesta-de-compras .center-shop {
    width: 620px;
    background: #fff;
  }
  #cesta-de-compras .center-shop .shopping {
    background: #fff;
  }
  #cesta-de-compras .center-shop .shopping li {
    background: #f1e8e0;
    padding: 20px;
    margin-top: 10px;
    border-bottom: 0px solid #c8b59e;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop {
    width: 100%;
    margin: 0 28px 0 0;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .img {
    width: 273px;
    height: auto;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .img img {
    width: 273px;
    height: auto;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text {
    margin-left: 20px;
    width: 270px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text .name {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #85685b;
    line-height: 30px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text .sub-name {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 14px;
    color: #4f2c1e;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text .description-title {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 13px;
    color: #4f2c1e;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text .description {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 12px;
    color: #85685b;
    line-height: 15px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text .price {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop {
    width: 100%;
    border-top: 2px solid #ad906e;
    margin: 35px 0 0 0;
    padding-top: 35px;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop .title-amount {
    text-align: left;
    display: inline-block;
    vertical-align: top;
    width: 49%;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop .title-amount p {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
    width: auto !important;
    line-height: 87px;
    margin: 0;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop div {
    width: 49%;
    display: inline-block;
    vertical-align: top;
    text-align: right;
    margin: auto;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop div .mais,
  #cesta-de-compras .center-shop .shopping li .amount-shop div .menos {
    height: 78px;
    width: 78px;
    font-size: 50px;
    padding-top: 10px;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop div .quantidade {
    height: 78px;
    width: 78px;
    font-size: 50px;
    padding-top: 10px;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop div a {
    margin-left: 42px;
    text-align: center;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 20px;
    color: #4f2c1e;
  }
  #cesta-de-compras .center-shop .freight-calculate {
    background: #f1e8e0;
    padding: 280px 0 15px 0;
  }
  #cesta-de-compras .center-shop .freight-calculate div {
    display: inline-block;
    vertical-align: middle;
  }
  #cesta-de-compras .center-shop .freight-calculate .calculate div {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0px 0 0px;
  }
  #cesta-de-compras .center-shop .freight-calculate .calculate div:first-child {
    margin: 0 10px 0 0;
  }
  #cesta-de-compras .center-shop .freight-calculate .calculate div p {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
    line-height: 30px;
    margin: 0 0 0 20px;
    text-align: left;
  }
  #cesta-de-compras .center-shop .freight-calculate .calculate div .freight-cep {
    display: block;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 20px;
    color: #4f2c1e;
    margin: 0 0 0 20px;
  }
  #cesta-de-compras .center-shop .freight-calculate .calculate div input {
    width: 229px;
    height: 70px;
    padding: 0 36px;
    margin: 0 58px;
  }
  #cesta-de-compras .center-shop .freight-calculate .calculate a {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 20px;
    color: #775ca7;
    text-transform: uppercase;
    display: block;
    float: right;
    margin: 20px 58px 20px 0;
    text-decoration: underline;
  }
  #cesta-de-compras .center-shop .freight-calculate .calculate .description {
    text-align: center;
    width: 100%;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 13px;
    color: #4f2c1e;
    margin: 80px 0 0 0px;
  }
  #cesta-de-compras .center-shop .freight-calculate .freight {
    width: calc(100% - 30px);
    margin: 25px 15px 0;
    display: block !important;
    padding: 40px 15px;
    border-bottom: 1px solid #ad906e;
    border-top: 1px solid #ad906e;
  }
  #cesta-de-compras .center-shop .freight-calculate .freight .text {
    float: left;
    line-height: 0px;
    margin: 0;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
    text-transform: none;
  }
  #cesta-de-compras .center-shop .freight-calculate .freight .number {
    float: right;
    margin: 0;
    line-height: 0px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #cesta-de-compras .center-shop .sub-total {
    width: 100%;
    display: block !important;
    padding: 40px 30px;
  }
  #cesta-de-compras .center-shop .sub-total .text {
    float: left;
    line-height: 0px;
    margin: 0;
    font-size: 26px;
  }
  #cesta-de-compras .center-shop .sub-total .number {
    float: right;
    margin: 0;
    line-height: 0px;
    font-size: 26px;
  }
  #cesta-de-compras .center-shop .static {
    position: absolute !important;
  }
  #cesta-de-compras .center-shop .concluir-compra {
    padding: 20px 0;
    background: #fff;
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0;
    z-index: 5;
  }
  #cesta-de-compras .center-shop .concluir-compra .box-concluir-compra {
    width: 620px;
    margin: 0 auto;
  }
  #cesta-de-compras .center-shop .concluir-compra .buttom {
    display: inline-block;
    vertical-align: top;
    width: 338px;
    float: right;
  }
  #cesta-de-compras .center-shop .concluir-compra .buttom a {
    height: 90px;
    line-height: 90px;
    width: 338px;
  }
  #cesta-de-compras .center-shop .concluir-compra .text {
    width: 222px;
    margin-left: 14px;
    display: inline-block;
    vertical-align: top;
  }
  #cesta-de-compras .center-shop .concluir-compra .text p {
    margin: 0;
  }
  #cesta-de-compras .center-shop .concluir-compra .text .total {
    text-transform: uppercase;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 20px;
    color: #4f2c1e;
  }
  #cesta-de-compras .center-shop .concluir-compra .text .preco {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 35px;
    color: #4f2c1e;
    line-height: 35px;
  }
  #cesta-de-compras .center-shop .concluir-compra .text span {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 14px;
    color: #4f2c1e;
  }
  #cesta-de-compras .align-border-shop {
    border-bottom: 1px solid #c8b59e;
    background: #fff;
    padding-top: 0;
    padding-bottom: 0px;
    margin-left: -310px;
    bottom: 582px;
    left: 50%;
    position: absolute;
  }
  #cesta-de-compras .align-border-shop .desconto {
    width: 100%;
    margin: 0 !important;
  }
  #cesta-de-compras .align-border-shop .desconto span {
    font-size: 15px !important;
    text-transform: uppercase;
  }
  #cesta-de-compras .align-border-shop .desconto p {
    font-size: 12px !important;
    text-transform: uppercase;
    color: #775ca7;
  }
  #cesta-de-compras .align-border-shop .voucher {
    background: #fff;
    margin: 0 !important;
    width: 100%;
    padding: 40px 0;
  }
  #cesta-de-compras .align-border-shop .voucher .code {
    display: inline-block;
    vertical-align: top;
  }
  #cesta-de-compras .align-border-shop .voucher .code p {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px !important;
    color: #4f2c1e;
    text-transform: uppercase;
    margin: 0 0 0 20px !important;
  }
  #cesta-de-compras .align-border-shop .voucher .code span {
    display: block;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 20px !important;
    color: #4f2c1e;
    text-align: left !important;
    margin: 0 0 0 20px !important;
  }
  #cesta-de-compras .align-border-shop .voucher .form-group {
    display: inline-block;
    vertical-align: middle;
  }
  #cesta-de-compras .align-border-shop .voucher .form-group input {
    width: 229px !important;
    height: 70px !important;
    padding: 0 36px !important;
    margin: 0 57px 0 0 !important;
  }
  #cesta-de-compras .align-border-shop .voucher .calcular {
    display: block;
    float: right;
  }
  #cesta-de-compras .align-border-shop .voucher .calcular a {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 20px !important;
    color: #775ca7;
    display: block;
    margin: 20px 58px 20px 0 !important;
    text-align: right;
  }
  #cesta-de-compras .align-border-shop .center-shop .keep-buy {
    max-width: 75%;
    display: block;
    margin: 0 auto;
    a {
      margin: 20px auto;
    }
  }
  .link-continue-shopping {
    text-align: center;
    position: absolute;
    bottom: 36px;
    left: 0;
    width: 100%;
    text-transform: uppercase;
  }
  .link-continue-shopping a {
    color: #775ca7;
    font-size: 12px;
    text-decoration: underline;
  }
  #cesta-de-compras .center-shop .concluir-compra {
    padding: 20px 10px;
  }
  #cesta-de-compras .center-shop .concluir-compra .box-concluir-compra {
    width: 100%;
  }
  #cesta-de-compras .center-shop .concluir-compra.static {
    bottom: 65px;
  }
}
@media (max-width: 639px) {
  #cesta-de-compras {
    padding: 0 5px 180px 5px;
  }
  #cesta-de-compras .shopping-top {
    padding: 25px 0;
  }
  #cesta-de-compras .shopping-top h2 {
    font-size: 23px;
  }
  #cesta-de-compras .center-shop {
    width: 310px;
  }
  #cesta-de-compras .center-shop .shopping li {
    padding: 10px;
    margin-top: 5px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop {
    margin: 0 14px 0 0;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .img {
    width: 136px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .img img {
    width: 136px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text {
    margin-left: 10px;
    width: 135px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text .name {
    font-size: 13px;
    line-height: 15px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text .sub-name {
    font-size: 7px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text .description-title {
    font-size: 7px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text .description {
    font-size: 6px;
    line-height: 8px;
  }
  #cesta-de-compras .center-shop .shopping li .product-shop .text .price {
    font-size: 13px;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop {
    border-top: 1px solid #ad906e;
    margin: 18px 0 0 0;
    padding-top: 18px;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop .title-amount p {
    font-size: 13px;
    line-height: 43px;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop div .mais,
  #cesta-de-compras .center-shop .shopping li .amount-shop div .menos {
    height: 39px;
    width: 39px;
    font-size: 25px;
    line-height: 38px;
    max-width: 39px !important;
    overflow: hidden;
    margin: 0 !important;
    padding: 5px 0 0 !important;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop div .quantidade {
    height: 39px;
    width: 39px;
    font-size: 22px;
    padding: 0 5px !important;
    vertical-align: top;
    line-height: 1.2em !important;
    max-width: 39px !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
  }
  #cesta-de-compras .center-shop .shopping li .amount-shop div a {
    margin-left: 21px;
    font-size: 10px;
  }
  #cesta-de-compras .center-shop .freight-calculate {
    padding: 2px 0 8px 0;
  }
  #cesta-de-compras .center-shop .freight-calculate div,
  #cesta-de-compras .center-shop .freight-calculate .calculate {
    width: 310px;
  }
  #cesta-de-compras .center-shop .freight-calculate div div,
  #cesta-de-compras .center-shop .freight-calculate .calculate div {
    width: 43%;
  }
  #cesta-de-compras .center-shop .freight-calculate div div:first-child,
  #cesta-de-compras .center-shop .freight-calculate .calculate div:first-child {
    margin: 0 5px 0 0;
  }
  #cesta-de-compras .center-shop .freight-calculate div div p,
  #cesta-de-compras .center-shop .freight-calculate .calculate div p {
    font-size: 13px;
    line-height: 15px;
    margin: 0 0 0 10px;
  }
  #cesta-de-compras .center-shop .freight-calculate div div .freight-cep,
  #cesta-de-compras .center-shop .freight-calculate .calculate div .freight-cep {
    font-size: 10px;
    margin: 0 0 0 10px;
  }
  #cesta-de-compras .center-shop .freight-calculate div div input,
  #cesta-de-compras .center-shop .freight-calculate .calculate div input {
    width: 100%;
    height: 35px;
    padding: 0 8px;
    margin: 0 0 0 21px;
    border: none !important;
  }
  #cesta-de-compras .center-shop .freight-calculate div a,
  #cesta-de-compras .center-shop .freight-calculate .calculate a {
    font-size: 10px;
    margin: 10px 15px 10px 0;
  }
  #cesta-de-compras .center-shop .freight-calculate div .description,
  #cesta-de-compras .center-shop .freight-calculate .calculate .description {
    font-size: 8px;
    margin: 32px auto 0;
    width: 170px;
    text-align: center;
  }
  #cesta-de-compras .center-shop .freight-calculate .freight {
    width: calc(100% - 20px);
    margin: 13px 10px 0;
    padding: 20px 5px;
  }
  #cesta-de-compras .center-shop .freight-calculate .freight .text {
    font-size: 13px;
  }
  #cesta-de-compras .center-shop .freight-calculate .freight .number {
    font-size: 13px;
  }
  #cesta-de-compras .center-shop .sub-total {
    padding: 20px 15px;
  }
  #cesta-de-compras .center-shop .sub-total .text {
    font-size: 13px;
  }
  #cesta-de-compras .center-shop .sub-total .number {
    font-size: 13px;
  }
  #cesta-de-compras .center-shop .concluir-compra .box-concluir-compra {
    width: 310px;
  }
  #cesta-de-compras .center-shop .concluir-compra .buttom {
    width: 169px;
    float: right;
  }
  #cesta-de-compras .center-shop .concluir-compra .buttom a {
    height: 45px;
    line-height: 45px;
    width: 169px;
  }
  #cesta-de-compras .center-shop .concluir-compra .text {
    width: 111px;
    margin-left: 7px;
  }
  #cesta-de-compras .center-shop .concluir-compra .text .total {
    font-size: 10px;
  }
  #cesta-de-compras .center-shop .concluir-compra .text .preco {
    font-size: 18px;
    line-height: 18px;
  }
  #cesta-de-compras .center-shop .concluir-compra .text span {
    font-size: 7px;
  }
  #cesta-de-compras .align-border-shop {
    margin-left: 0;
    bottom: 0;
    position: relative;
    left: 0;
    border-bottom: none !important;
  }
  #cesta-de-compras .align-border-shop .voucher {
    padding: 20px 0;
  }
  #cesta-de-compras .align-border-shop .voucher .code p {
    font-size: 13px !important;
    margin: 0 0 0 10px !important;
  }
  #cesta-de-compras .align-border-shop .voucher .code span {
    font-size: 10px !important;
    margin: 0 0 0 10px !important;
  }
  #cesta-de-compras .align-border-shop .voucher .box-calculate-voucher {
    float: right;
    text-align: right;
  }
  #cesta-de-compras .align-border-shop .voucher .form-group {
    margin-right: 15px;
    display: block !important;
  }
  #cesta-de-compras .align-border-shop .voucher .form-group input {
    width: 115px !important;
    height: 35px !important;
    padding: 0 18px !important;
    margin: 0 !important;
  }
  #cesta-de-compras .align-border-shop .voucher .calcular {
    display: block !important;
    float: none;
  }
  #cesta-de-compras .align-border-shop .voucher .calcular a {
    font-size: 10px !important;
    margin: 0 15px 0 0 !important;
  }
  #cesta-de-compras .align-border-shop .desconto {
    margin-bottom: 20px !important;
  }
  #cesta-de-compras .align-border-shop .desconto span {
    font-size: 8px !important;
  }
  #cesta-de-compras .align-border-shop .desconto p {
    font-size: 12px !important;
  }
}

.body-entrega {
  padding-right: 0 !important;
  background-color: #f1e8e0;
}
.body-entrega .border-dual-color,
.body-entrega footer.container {
  display: none !important;
}

#identificacao-entrega {
  background: #f1e8e0;
  color: #4f2c1e;
  padding: 28px 0 100px;
  min-height: 640px;
}
#identificacao-entrega .title {
  color: inherit;
  font-size: 20px;
  margin: 0 0 20px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'martelbold';
}
#identificacao-entrega .subtitle {
  color: inherit;
  font-size: 14px;
  margin: 0 0 40px;
  text-align: center;
}
#identificacao-entrega .container {
  width: 895px;
  padding: 0;
  margin: 0 auto;
}
#identificacao-entrega .container:before,
#identificacao-entrega .container:after {
  display: table;
  content: "";
}
#identificacao-entrega .container:after {
  clear: both;
}

#identificacao-entrega .cartao-preview {
  margin-top:  10px;
  background-color: #fff;
  position: relative;
}

#identificacao-entrega .cartao-preview.foto {
  width: $width;
  height: ($width*2/3);
  border: 1px dashed;
}

#identificacao-entrega .cartao-preview.foto .cartao-img{
  height: 75%;
}

#identificacao-entrega .cartao-preview.foto .cartao-img .cartao-preview-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}

#identificacao-entrega .cartao-img .cartao-preview-img{
  border-radius: 7px;
  max-width: 100%;
  height: auto;
}

#identificacao-entrega .cartao-preview-text {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: clip;
  bottom: 35px;
  font-size: 2rem;
  left: 45px;
  right: 45px;
  top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 1;
  position: absolute;
  -webkit-transform: rotate(-14deg);
  transform: rotate(-14deg);
  margin: 10px;
  font-family: 'Nanum Pen Script', cursive;
}

#identificacao-entrega .cartao-preview-text p {
  font-family: 'Nanum Pen Script', cursive;
  font-size: 1.7rem;
  margin: 0;
  line-height: 1;
}

#identificacao-entrega .cartao-preview.foto .cartao-preview-text {
  width: 100%;
  text-align: center;
  max-height: 40.8px;
  position: initial;
  -webkit-transform: none;
  transform: none;
  margin: 0;
}

#identificacao-entrega .cartao-preview.foto .cartao-preview-copy {
  line-height: 0.8;
  font-size: 24px;
  text-overflow: clip;
  overflow: hidden;
}

#identificacao-entrega .cartao-preview-signature{
  max-height: 28px;
}

.box-address {
  width: 585px;
  float: left;
  padding-right: 50px;
  border-right: 1px solid #fff;
}
.box-address ul,
.box-address li {
  list-style: none;
}
.box-address ul {
  padding: 0;
  margin: 0;
}
.box-address ul:before,
.box-address ul:after {
  display: table;
  content: "";
}
.box-address ul:after {
  clear: both;
}
.box-address-item {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  float: left;
  width: 49.5%;
  height: 180px;
  padding: 18px;
  position: relative;
  margin: 0 1% 5px 0;
  background-color: #fff;
  border: 2px solid #fff;
}
.box-address-item:nth-child(2n+0) {
  margin-right: 0;
}
.box-address-item h3 {
  color: #4f2c1e;
  font-size: 14px;
  margin: 0 0 10px;
  padding-right: 45px;
  font-family: 'martelextrabold';
}
.box-address-item p {
  color: #85685b;
  font-size: 12px;
  line-height: 17px;
  font-family: 'marteldemibold';
}
.box-address-item p strong {
  font-family: 'martelextrabold';
}
.box-address-item .check-address input[type="radio"] {
  margin: 0;
  left: 0;
  top: 4px;
}
.box-address-item .check-address input[type="radio"]:after {
  border: 1px solid #72554a;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 13px;
  background: #fff;
  line-height: 16px;
  text-align: center;
  color: #85685b;
}
.box-address-item .check-address input[type="radio"]:checked:after {
  content: '✔';
}

.box-address-item--active {
  border-color: #bba285;
}
.box-text-message-yes {
  line-height: 1.5em;
}
.box-text-message-formats,
.box-text-message-formats-photo {
  display: none;
  padding: 15px;
  background-color: #cdbeb8;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin-top: 4px;
}
.box-text-message-formats label.checkbox-inline,
.box-text-message-formats-photo label.checkbox-inline {
  margin-top: 0 !important;
}
.box-text-message-formats input[type="radio"],
.box-text-message-formats-photo input[type="radio"] {
  margin: 0;
  left: 0;
  top: 4px;
  display: inline-block;
}
.box-text-message-formats textarea,
.box-text-message-formats-photo textarea {
  width: 100%;
  height: 110px;
}
.box-text-message-formats .checkbox-inline:first-child,
.box-text-message-formats-photo .checkbox-inline:first-child {
  padding-left: 0 !important;
}
.box-address-register {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  height: 180px;
  float: left;
  width: 49.5%;
  cursor: pointer;
  position: relative;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: #cdbeb8;
  border: 2px solid #cdbeb8;
}
.box-address-register.has-error {
  border-color: #ae1f6f;
}
.box-address-register-container {
  top: 50%;
  left: 0;
  width: 100%;
  color: #4f2c1e;
  font-size: 12px;
  padding-top: 80px;
  text-align: center;
  position: absolute;
  font-family: 'martelextrabold';
  text-transform: uppercase;
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: url("../img/icon-circle-add.png") center top no-repeat;
}
.box-address-register-container p {
  font-size: 12px;
}
.box-address-options {
  top: 15px;
  right: 15px;
  position: absolute;
}
.box-address-options li {
  display: inline-block;
}
.box-address-options li:first-child {
  margin-right: 15px;
}
.box-address-options span {
  height: 14px;
  cursor: pointer;
  overflow: hidden;
  text-indent: -99em;
  display: inline-block;
  background: url("../img/sprite-icon-address.png") left top no-repeat;
  background-size: auto 14px;
}
.box-address-options .box-address-options--edit {
  width: 8px;
}
.box-address-options .box-address-options--delete {
  width: 11px;
  background-position: -10px 0;
}
.check-address {
  bottom: 15px;
  left: 18px;
  color: #775ca7 !important;
  font-size: 12px;
  position: absolute;
  text-transform: uppercase;
  font-family: 'martelbold';
}

form .has-error label.check-address {
  color: #775ca7 !important;
}
.box-text-gift {
  width: 310px;
  float: right;
  color: #4f2c1e;
  padding-left: 35px;
}
.box-text-gift h4 {
  color: inherit;
  font-size: 14px;
  margin: 0 0 15px;
  font-family: 'martelbold';
  text-transform: uppercase;
}
.box-text-gift ul,
.box-text-gift li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.box-text-gift .box-fake-radio {
  margin-bottom: 20px;
}
.box-text-gift .radio-inline input[type="radio"]:after {
  border: 2px solid #fff;
  width: 12px;
  height: 12px;
}
.box-text-gift .radio-inline input[type="radio"]:checked:after {
  border: 3px solid #fff;
}
.box-text-gift-option {
  font-size: 12px;
  display: none;
}
.box-text-gift-option h5 {
  line-height: 15px;
  margin: 0 0 10px;
  font-size: inherit;
  font-family: 'martelbold';
}
.box-text-gift-option p {
  color: #85685b;
  line-height: 17px;
}
.box-text-gift-option .btn {
  margin-top: 45px;
}
.box-text-gift-message {
  padding: 18px 20px;
  color: #85685b;
  line-height: 19px;
  margin-top: 20px;
  background-color: #ded5cd;
}
.box-text-gift-message .link {
  color: #775ca7;
  font-family: 'martelbold';
  text-transform: uppercase;
  padding-bottom: 1px;
  display: inline-block;
  margin-top: 16px;
  cursor: pointer;
  border-bottom: 1px solid #775ca7;
}
.box-validate.has-error .box-address-item,
.box-validate.has-error .box-address-register {
  border-color: #ae1f6f !important;
}
.box-validate.has-error .radio-inline {
  color: #ae1f6f !important;
}
.box-validate.has-error .radio-inline input[type="radio"]:after {
  border-color: #ae1f6f;
}
.box-footer-address {
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  position: fixed;
  z-index: 900;
  border-top: 1px solid #ad906e;
}
.box-footer-address .container {
  height: 80px;
  position: relative;
}
.box-footer-address .btn {
  position: absolute;
  right: 0;
  top: 50%;
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  line-height: 24px;
  width: 175px;
}
.box-footer-address-btn.btn-disabled {
  cursor: default;
  border-color: #cbbdb8;
  color: #cbbdb8;
  background-color: #e8e0d6;
}

@media (max-width: 800px) {
  #identificacao-entrega {
    padding-top: 40px;
  }
  #identificacao-entrega .form-group {
    margin-bottom: 0 !important;
  }
  #identificacao-entrega .box-address-item.form-group {
    margin-bottom: 5px !important;
  }
  #identificacao-entrega .container {
    width: 100%;
    padding: 0 15px;
  }
  .box-address {
    margin-bottom: 60px;
  }
  .box-address-item .check-address input[type="radio"] {
    top: -2px;
  }
  .box-address,
  .box-text-gift {
    width: 100%;
    float: none;
    padding: 0;
    border: none;
  }
  .box-address:before,
  .box-text-gift:before,
  .box-address:after,
  .box-text-gift:after {
    display: table;
    content: "";
  }
  .box-address:after,
  .box-text-gift:after {
    clear: both;
  }
  .box-text-gift {
    min-height: 120px;
  }
  .check-address {
    font-size: 14px !important;
  }
  .box-footer-address {
    height: auto;
    padding: 15px 0;
    text-align: center;
  }
  .box-footer-address .container {
    height: auto;
  }
  .box-footer-address .btn {
    position: relative;
    height: 65px;
    top: 0;
    -moz-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    line-height: 65px;
  }
}

@media (max-width: 640px) {
  #identificacao-entrega .radio-inline.check-address {
    line-height: 12px !important;
    font-size: 12px !important;
  }
  .box-address-item,
  .box-address-register {
    float: none;
    width: 100%;
    margin: 0 0 8px !important;
  }
  .box-fake-radio {
    text-align: center;
  }
  .box-fake-radio li {
    display: inline-block;
    margin: 0 10px;
  }
  .box-fake-radio li label {
    font-size: 14px !important;
  }
  .box-text-gift h4 {
    text-align: center;
  }
  .box-text-gift .box-fake-radio {
    text-align: left;
  }
  .box-text-gift-option {
    padding: 0 20px;
  }
  .box-text-gift-option h5 {
    padding: 0;
    background: none;
  }
  .box-text-gift-option p {
    text-align: center;
  }
  .box-text-gift-option .btn {
    height: 40px;
    line-height: 40px;
    display: block;
    margin: 40px 0 auto;
  }
  .box-text-gift-message {
    text-align: center;
  }
  .box-text-gift-message strong {
    display: block;
    margin-bottom: 10px;
  }
  .box-footer-address .btn {
    height: 50px;
    line-height: 50px;
  }
}

.title-pattern-checkout {
  font-size: 17px;
  text-align: left;
  margin-bottom: 26px;
  margin-top: 0;
  font-family: 'martelbold';
  text-transform: uppercase;
}

.form-choices-pagto-content {
  margin: 0 auto;
}

.informacao-pagamento-aling {
  width: 900px;
  margin: auto;
}
.informacao-pagamento-aling h4 {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: 'martelbold';
  margin: 0;
  padding: 10px 0;
}

.informacao-pagamento-aling .table-top ul {
  height: 70px;
  margin-bottom: 35px;
}
.informacao-pagamento-aling .table-top ul li {
  height: 70px;
  width: auto;
}
.informacao-pagamento-aling .table-top ul li.active a {
  color: #4f2c1e;
}
.informacao-pagamento-aling .table-top ul li.active a .img img {
  opacity: 1;
}
.informacao-pagamento-aling .table-top ul li a {
  display: block;
  height: 70px;
  font-size: 12px;
}
.informacao-pagamento-aling .table-top ul li a .img {
  height: 36px;
  text-align: center;
  display: block;
}
.informacao-pagamento-aling .table-top ul li a .img img {
  opacity: 0.4;
}

.cartao-foto-preview {
  width: 150px;
  margin-left: 5px;
}

#credito {
  margin-right: 0;
  padding: 0 10px;
}
#credito div.validade {
  margin: 15px auto;
  overflow: hidden;
}
#credito div.validade label {
  padding: 0;
}
#credito div.seguranca {
  margin: 15px auto;
}
#credito div.seguranca label {
  padding: 0;
}
#credito div.seguranca div {
  padding: 0 8px 0 0;
  margin: 0;
  vertical-align: middle;
}
#credito div.seguranca div.back-card {
  margin-top: 7px;
  padding: 0;
}
#credito div.seguranca div.back-card img {
  width: 36px;
  display: inline-block;
  vertical-align: middle;
}
#credito div.seguranca div.back-card span {
  margin-left: 10px;
}
#credito .description {
  width: 132px;
  margin-bottom: 0;
  font-size: 10px;
  margin-left: 2px;
  line-height: 12px;
  display: inline-block;
  vertical-align: middle;
}
#credito label small {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 9px;
  color: #4f2c1e;
}

#boleto .bold-text,
#paypal .bold-text {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
#boleto .bold-text .description,
#paypal .bold-text .description {
  margin-bottom: 15px;
  font-size: 16px !important;
  line-height: 1.6 !important;
  font-weight: normal;
}
#boleto .bold-text p:last-child,
#paypal .bold-text p:last-child {
  margin-bottom: 0;
}
#paypal {
  font-size: 16px;
}
#modal-cartao {
  top: 100px;
}

#modal-cartao .modal-body {
  overflow: hidden;
}
#modal-cartao .left {
  width: 398px;
  margin-left: 11px;
  float: left;
}
#modal-cartao .left textarea {
  width: 100%;
  height: 100px;
}
#modal-cartao .left input {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
#modal-cartao .right {
  width: 342px;
  padding: 15px;
  margin: 26px 11px 0 0;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: #ded5cd;
  text-align: center;
  float: right;
  position: relative;
}
#modal-cartao .right:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 12px 9px 0;
  border-color: transparent #ded5cd transparent transparent;
  content: '';
  left: -12px;
  top: 43px;
  position: absolute;
}
#modal-cartao .right .form-group {
  margin: 0;
}
#modal-cartao .right label {
  text-align: left;
}
#modal-cartao .right .help-block {
  font-size: 10px;
  margin-bottom: 20px;
  text-align: left;
}
#modal-cartao .right .nav li {
  width: 110px;
  margin: 3px 0;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}
#modal-cartao .right .nav li:nth-child(even) {
  margin-left: 10px;
}
#modal-cartao .right .nav li a {
  padding: 0 0 0 20px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #85685b;
  position: relative;
}
#modal-cartao .right .nav li:hover a,
#modal-cartao .right .nav li a:focus {
  background: none;
}
#modal-cartao .right .nav li a:after {
  width: 16px;
  height: 16px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  background: #fff;
  content: '';
  top: -1px;
  left: -1px;
  position: absolute;
}
#modal-cartao .right .nav li.active a:after {
  background: #85685b;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  border: #fff 5px solid;
}
#modal-cartao .right .scroller {
  height: 300px;
  margin-top: 20px;
  overflow: auto;
}
#modal-cartao .right .tab-content {
  text-align: left;
}
#modal-cartao .right .tab-content ul {
  padding: 0;
  margin: 0;
}
#modal-cartao .right .tab-content ul li {
  margin-top: 5px;
  list-style: none;
}
#modal-cartao .right .tab-content ul li:first-child {
  margin: 0;
}
#modal-cartao .right .tab-content ul li a {
  padding: 8px 10px 5px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #4f2c1e;
  text-decoration: none;
  display: block;
  background: #fff;
}
#modal-cartao .right .tab-content ul li a:hover {
  color: #e9e0d7;
  background: #85685b;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#modal-cadastro-enderecos form {
  padding: 15px;
}
#modal-cadastro-enderecos form .row {
  margin-left: -10px;
  margin-right: -10px;
}
#modal-cadastro-enderecos form .row div[class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}
#modal-cadastro-enderecos form button {
  margin-top: 15px;
}


.bold-text {
  margin-top: 10px;
}
.bold-text .description {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
  line-height: 18px;
}
.bold-text .bancos {
  height: auto !important;
  margin-bottom: 0 !important;
  padding: 0;
}
.bold-text .bancos li {
  list-style: none;
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 992px) and (max-width: 1260px) {
  .informacao-pagamento-aling {
    width: 698px;
  }
  .informacao-pagamento-aling h4 {
    padding: 10px 0;
  }
  .informacao-pagamento-aling .table-top ul {
    height: 54px;
    margin-bottom: 27px;
  }
  .informacao-pagamento-aling .table-top ul li {
    height: 54px;
  }
  .informacao-pagamento-aling .table-top ul li a {
    height: 54px;
  }
  .informacao-pagamento-aling .table-top ul li a .img {
    height: 28px;
  }
  .informacao-pagamento-aling .table-top ul.bancos img {
    height: 28px;
  }
  #credito form {
    width: 220px;
    padding: 0 8px;
  }
  #credito form div {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  #credito form div.validade {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  #credito form div.seguranca {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  #credito form div.seguranca label {
    padding: 0;
  }
  #credito form div.seguranca div {
    padding: 0 6px 0 0;
  }
  #credito form div.seguranca div.back-card {
    margin-top: 5px;
  }
  #credito form div.seguranca div.back-card img {
    width: 28px;
  }
  #credito form div.seguranca div.back-card .description {
    width: 102px;
    font-size: 8px;
    margin-left: 2px;
    line-height: 9px;
  }
  #credito form div label small {
    font-size: 7px;
  }
  .bold-text {
    margin-top: 8px;
  }
  .bold-text .description {
    font-size: 9px;
    line-height: 14px;
  }
  #resumo-pedido .pedido {
    padding: 0;
  }
  #resumo-pedido .pedido .text {
    padding: 0;
  }
  #resumo-pedido .pedido .text .local {
    font-size: 9px;
  }
  #resumo-pedido .pedido .text .description {
    font-size: 9px;
    margin-bottom: 2px;
  }
  #resumo-pedido .pedido .data-entrega {
    margin-top: 12px;
    padding: 0;
  }
  #resumo-pedido .pedido .data-entrega label {
    font-size: 9px;
  }
  #resumo-pedido .pedido .data-entrega div input {
    width: 81px;
  }
  #resumo-pedido .pedido .data-entrega .alterar p {
    width: 57px;
    margin-top: 3px;
    font-size: 8px;
    line-height: 11px;
  }
  #resumo-pedido .pedido .presente {
    padding: 12px 0 0 0;
  }
  #resumo-pedido .pedido .presente .title {
    font-size: 11px;
    font-family: 'martelheavy';
  }
  #resumo-pedido .pedido .presente .description {
    font-size: 9px;
  }
  #modal-cartao .left {
    width: 309px;
    margin-left: 9px;
  }
  #modal-cartao .left textarea {
    height: 68px;
  }
  #modal-cartao .right {
    width: 265px;
    padding: 12px;
    margin: 20px 9px 0 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  #modal-cartao .right:after {
    border-width: 7px 9px 7px 0;
    left: -9px;
    top: 33px;
  }
  #modal-cartao .right .help-block {
    font-size: 8px;
    margin-bottom: 16px;
  }
  #modal-cartao .right .nav li {
    width: 85px;
    margin: 2px 0;
  }
  #modal-cartao .right .nav li:nth-child(even) {
    margin-left: 8px;
  }
  #modal-cartao .right .nav li a {
    padding: 0 0 0 16px;
    font-size: 9px;
  }
  #modal-cartao .right .nav li a:after {
    width: 12px;
    height: 12px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  }
  #modal-cartao .right .nav li.active a:after {
    border-width: 4px;
  }
  #modal-cartao .right .scroller {
    height: 233px;
    margin-top: 16px;
  }
  #modal-cartao .right .tab-content ul li {
    margin-top: 4px;
  }
  #modal-cartao .right .tab-content ul li a {
    padding: 6px 8px 4px;
    font-size: 8px;
  }
}
@media (max-width: 991px) {
  .informacao-pagamento-aling {
    max-width: 620px;
    margin: auto;
    background: #fff;
  }
  .informacao-pagamento-aling .form {
    padding: 35px 20px 10px;
    background: #c8b59e;
    margin-bottom: 20px;
  }
  .form-choices-pgto {
    padding-top: 20px;
  }
  #credito {
    margin: 20px 0;
    padding: 0 0px;
  }
  #credito form {
    width: 100%;
    background: #e8e0d7;
    padding: 20px;
  }
  #credito form div {
    margin: 15px auto;
  }
  #credito form div.validade {
    margin: 15px auto;
    overflow: hidden;
  }
  #credito form div.validade label {
    padding: 0;
  }
  #credito form div.seguranca {
    margin: 15px auto;
  }
  #credito form div.seguranca label {
    padding: 0;
  }
  #credito form div.seguranca div {
    padding: 0 8px 0 0;
    margin: 0;
    vertical-align: middle;
  }
  #credito form div.seguranca div.back-card img {
    width: 72px;
  }
  #credito form div.seguranca div.back-card .description {
    width: 211px;
    margin-bottom: 0;
    font-size: 14px;
    margin-left: 10px;
    line-height: 16px;
  }
  #credito form div label small {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 14px;
    color: #4f2c1e;
  }
  #credito form button {
    width: 330px;
    display: block;
    float: none;
    margin: auto;
  }
  .bold-text {
    margin-top: 0;
    width: 100%;
    background: #e8e0d7;
    padding: 40px;
  }
  .bold-text .description {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 20px;
    color: #4f2c1e;
    line-height: 25px;
    text-align: center;
  }
  .bold-text .bancos {
    margin: 60px 0;
    text-align: center;
  }
  .bold-text .bancos li {
    width: 90px;
    margin: 0 5px;
  }
  .bold-text a {
    display: block;
    float: none;
    width: 330px;
    margin: 50px auto auto auto;
  }
  #resumo-pedido {
    text-align: center;
    border-left: none;
    padding: 20px 0;
  }
  #resumo-pedido h5 {
    font-size: 25px;
    text-align: center;
  }
  #resumo-pedido .pedido .text {
    padding: 0 10px;
  }
  #resumo-pedido .pedido .text .title {
    text-transform: uppercase;
    font-family: 'martelheavy';
  }
  #resumo-pedido .pedido .text .local {
    font-family: 'martelheavy';
    font-weight: normal;
    font-size: 26px;
    color: #85685b;
  }
  #resumo-pedido .pedido .text .description {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #85685b;
    margin-bottom: 2px;
  }
  #resumo-pedido .pedido .text a {
    display: block;
  }
  #resumo-pedido .pedido .data-entrega {
    margin-top: 15px;
    padding: 0;
    text-align: center;
    overflow: hidden;
  }
  #resumo-pedido .pedido .data-entrega label {
    padding: 0;
    font-family: 'martelheavy';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #resumo-pedido .pedido .data-entrega div {
    padding: 0;
  }
  #resumo-pedido .pedido .data-entrega div input {
    width: 225px;
    height: 60px;
    text-align: center;
    margin: auto;
  }
  #resumo-pedido .pedido .data-entrega .alterar {
    text-align: center;
    margin-bottom: 20px;
  }
  #resumo-pedido .pedido .data-entrega .alterar p {
    width: auto;
    margin-top: 4px;
    font-size: 20px;
    line-height: 25px;
  }
  #resumo-pedido .pedido .presente {
    padding: 25px 10px 0 10px;
    width: calc(100% - 30px);
    margin: 0 15px;
  }
  #resumo-pedido .pedido .presente .title {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
    font-family: 'martelheavy';
  }
  #resumo-pedido .pedido .presente .description {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #85685b;
    margin-bottom: 0;
  }
  #modal-cartao .modal-body {
    padding: 50px 0 50px;
  }
  #modal-cartao .left {
    width: 546px;
    margin: auto;
    text-align: center;
    float: none;
  }
  #modal-cartao .left div {
    text-align: left;
  }
  #modal-cartao .left textarea {
    height: 178px;
  }
  #modal-cartao .left button {
    width: 314px;
    margin-top: 40px;
    float: none !important;
  }
  #modal-cartao .left .mensagens {
    width: 100%;
    padding: 15px;
    margin-bottom: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #ded5cd;
    text-align: center;
    position: relative;
  }
  #modal-cartao .left .mensagens:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 12px 9px;
    border-color: transparent transparent #ded5cd transparent;
    content: '';
    left: 22px;
    top: -12px;
    position: absolute;
  }
  #modal-cartao .left .mensagens p {
    margin: 0;
    font-size: 20px;
  }
  #modal-cartao .left .mensagens a {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 17px;
    color: #775ca7;
    text-transform: uppercase;
    text-decoration: underline;
  }
  #modal-cartao .right {
    width: 546px;
    padding: 30px;
    margin: 0 auto;
    text-align: center;
    top: 308px;
    left: 37px;
    position: absolute;
    display: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  #modal-cartao .right:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 12px 9px;
    border-color: transparent transparent #ded5cd transparent;
    content: '';
    left: 22px;
    top: -12px;
    position: absolute;
  }
  #modal-cartao .right .form-group {
    margin: 0;
  }
  #modal-cartao .right label {
    text-align: center;
    font-size: 20px;
  }
  #modal-cartao .right .help-block {
    font-size: 15px;
    margin-bottom: 30px;
    text-align: center;
  }
  #modal-cartao .right .nav li {
    width: 170px;
  }
  #modal-cartao .right .nav li:nth-child(even) {
    margin-left: 30px;
  }
  #modal-cartao .right .nav li a {
    padding: 0 0 0 30px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 20px;
    color: #85685b;
  }
  #modal-cartao .right .nav li a:after {
    top: 5px;
  }
  #modal-cartao .right .scroller {
    height: 380px;
    margin-top: 30px;
  }
  #modal-cartao .right .tab-content {
    text-align: center;
  }
  #modal-cartao .right .tab-content ul li a {
    padding: 8px 10px 5px;
    font-size: 20px;
  }
  #modal-cartao.open .left .mensagens {
    height: 645px;
  }
  #modal-cartao.open .right {
    display: block;
  }
}
@media (max-width: 639px) {
  .title-pattern-checkout {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 7px;
  }
  .form-choices-pagto-content,
  .resumo-pedido-wrapper {
    width: 100%;
  }
  .from-group-number-credit {
    margin-top: 15px !important;
  }
  .informacao-pagamento-aling {
    width: 310px;
  }
  .informacao-pagamento-aling .form {
    padding: 18px 10px;
  }
  #credito {
    margin: 10px 0;
    padding: 0 20px;
  }
  #credito div.seguranca div.back-card {
    margin-top: 3px;
  }
  #credito form {
    padding: 10px;
  }
  #credito form div {
    margin: 8px auto;
  }
  #credito form div.validade {
    margin: 8px auto;
  }
  #credito form div.seguranca {
    margin: 8px auto;
  }
  #credito form div.seguranca div {
    padding: 0 4px 0 0;
  }
  #credito form div.seguranca div.back-card {
    margin-top: 4px;
  }
  #credito form div.seguranca div.back-card img {
    width: 36px;
  }
  #credito form div.seguranca div.back-card .description {
    width: 106px;
    font-size: 7px;
    margin-left: 5px;
    line-height: 8px;
  }
  #credito form div label small {
    font-size: 7px;
  }
  #credito form button {
    width: 165px;
  }
  #boleto .bold-text,
  #paypal .bold-text {
    width: 90%;
    padding: 20px 30px;
  }
  .bold-text {
    padding: 20px;
  }
  .bold-text .description {
    font-size: 10px;
    line-height: 13px;
  }
  .bold-text .bancos {
    margin: 30px 0;
  }
  .bold-text .bancos li {
    width: 45px;
    height: 45px !important;
    margin: 0 3px;
  }
  .bold-text .bancos li img {
    width: 100%;
  }
  .bold-text a {
    width: 165px;
    margin: 25px auto auto auto;
  }
  #resumo-pedido {
    padding: 0;
  }
  #resumo-pedido h5 {
    margin: 0 0 15px;
    font-size: 17px;
  }
  #resumo-pedido .pedido {
    padding: 0 20px;
  }
  #resumo-pedido .pedido .text {
    padding: 0 5px;
  }
  #resumo-pedido .pedido .text .local {
    font-size: 13px;
  }
  #resumo-pedido .pedido .text .description {
    font-size: 13px;
    margin-bottom: 1px;
  }
  #resumo-pedido .pedido .data-entrega {
    margin: 10px 6px 15px;
    padding: 0;
    border-bottom: 1px solid #4f2c1e;
    text-align: center;
  }
  #resumo-pedido .pedido .data-entrega label {
    font-size: 13px;
    margin: 0 12px;
  }
  #resumo-pedido .pedido .data-entrega .form-group {
    margin-bottom: 0;
  }
  #resumo-pedido .pedido .data-entrega div input {
    width: 100% !important;
    height: 40px;
  }
  #resumo-pedido .pedido .data-entrega .alterar {
    width: 69px;
    text-align: left;
    margin: 0;
  }
  #resumo-pedido .pedido .data-entrega .alterar p {
    margin: 0;
    font-size: 11px;
    line-height: 13px;
  }
  #resumo-pedido .pedido .data-entrega .form-group,
  #resumo-pedido .pedido .data-entrega .alterar {
    float: none;
    vertical-align: middle;
    display: inline-block;
  }
  #resumo-pedido .pedido .presente {
    padding: 15px 5px 10px 5px;
    width: calc(100% - 16px);
    margin: 8px 8px 0;
    border-bottom: 1px solid #4f2c1e;
  }
  #resumo-pedido .pedido .presente .title {
    font-size: 13px;
    font-family: 'martelheavy';
  }
  #resumo-pedido .pedido .presente .description {
    font-size: 13px;
  }
  #modal-cartao .modal-body {
    padding: 25px 0 25px;
  }
  #modal-cartao .left {
    width: 273px;
  }
  #modal-cartao .left textarea {
    height: 89px;
  }
  #modal-cartao .left button {
    width: 157px;
    margin-top: 20px;
  }
  #modal-cartao .left .mensagens {
    padding: 8px;
    margin-bottom: 15px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  #modal-cartao .left .mensagens:after {
    border-width: 0 5px 6px 5px;
    left: 11px;
    top: -6px;
  }
  #modal-cartao .left .mensagens p {
    font-size: 10px;
  }
  #modal-cartao .left .mensagens a {
    font-size: 9px;
  }
  #modal-cartao .right {
    width: 273px;
    padding: 15px;
    top: 154px;
    left: 19px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
  #modal-cartao .right:after {
    border-width: 0 5px 6px 5px;
    left: 11px;
    top: -6px;
  }
  #modal-cartao .right label {
    font-size: 10px;
  }
  #modal-cartao .right .help-block {
    font-size: 8px;
  }
  #modal-cartao .right .nav li {
    width: 85px;
  }
  #modal-cartao .right .nav li:nth-child(even) {
    margin-left: 15px;
  }
  #modal-cartao .right .nav li a {
    padding: 0 0 0 15px;
    font-size: 10px;
  }
  #modal-cartao .right .nav li a:after {
    top: 3px;
  }
  #modal-cartao .right .scroller {
    height: 190px;
    margin-top: 15px;
  }
  #modal-cartao .right .tab-content ul li a {
    padding: 4px 5px 3px;
    font-size: 10px;
  }
  #modal-cartao.open .left .mensagens {
    height: 323px;
  }
}

#identificacao-sucesso {
  background: #f1e8e0;
  padding: 35px 0 65px 0;
}
#identificacao-sucesso .compra-imprimir {
  overflow: hidden;
  padding: 1rem 0;
  border-top: 1px solid #c6b59e;
  border-bottom: 1px solid #c6b59e;
  margin: 3rem 40px 2rem 0;
}
#identificacao-sucesso .compra-imprimir p,
#identificacao-sucesso .compra-imprimir a {
  display: inline-block;
}
#identificacao-sucesso .compra-imprimir p {
  margin-top: 10px;
}
#identificacao-sucesso .compra-imprimir a {
  float: right;
  margin: 0 !important;
}
#identificacao-sucesso .center-identification-success {
  width: 925px;
  margin: auto;
}
#identificacao-sucesso .center-identification-success h2 {
  text-align: left;
  margin: 0 0 3rem;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 20px;
  color: #4f2c1e;
  text-transform: uppercase;
}
#identificacao-sucesso .center-identification-success .left-success {
  margin-top: 56px;
  width: 620px;
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #fff;
}
#identificacao-sucesso .center-identification-success .left-success .text {
  text-align: left;
  margin-bottom: 35px;
}
#identificacao-sucesso .center-identification-success .left-success .text .title {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
}
#identificacao-sucesso .center-identification-success .left-success .text p {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #85685b;
  margin-bottom: 6px;
}
#identificacao-sucesso .center-identification-success .left-success .text a {
  margin-top: 30px;
}
#identificacao-sucesso .center-identification-success .left-success .text span {
  display: block;
  margin-top: 5px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 8px;
  color: #85685b;
}

.dados-do-comprador {
  padding: 0;
}
.dados-do-comprador li {
  width: 195px;
  margin: 0 5px;
  display: inline-block;
  vertical-align: top;
  list-style: none;
}
.dados-do-comprador li:first-child {
  margin-left: 0;
}
.dados-do-comprador li .title {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
  margin-bottom: 10px;
}
.dados-do-comprador li p {
  margin: 0;
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #85685b;
}
.dados-do-comprador li p strong {
  font-family: 'martelextrabold';
  font-weight: normal;
  font-size: 12px;
  color: #85685b;
}
.dados-do-comprador a {
  margin-top: 25px;
  width: 160px;
}

@media (max-width: 991px) {
  #identificacao-sucesso {
    background: #fff;
    padding: 30px 0 50px 0;
    width: 640px;
    margin: auto;
  }
  #identificacao-sucesso .center-identification-success {
    width: 620px;
    margin: auto;
  }
  #identificacao-sucesso .center-identification-success h2 {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 47px;
    color: #4f2c1e;
    background: #e8e0d7;
    text-transform: none;
    padding: 35px 0;
  }
  #identificacao-sucesso .center-identification-success .left-success {
    margin: auto;
    width: 620px;
    border: none;
    display: block;
    background: #e8e0d7;
  }
  #identificacao-sucesso .center-identification-success .left-success .text {
    width: 620px;
    margin: auto;
    text-align: center;
    margin-bottom: 35px;
  }
  #identificacao-sucesso .center-identification-success .left-success .text .title {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
    padding: 0 70px;
  }
  #identificacao-sucesso .center-identification-success .left-success .text p {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #85685b;
    margin-bottom: 6px;
    padding: 0 70px;
  }
  #identificacao-sucesso .center-identification-success .left-success .text a {
    width: 390px;
    margin-top: 30px;
  }
  #identificacao-sucesso .center-identification-success .left-success .text span {
    display: block;
    margin-top: 5px;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 14px;
    color: #85685b;
  }
  .dados-do-comprador {
    width: 620px;
    padding: 0 0 40px 0;
    margin: auto auto 0 auto;
  }
  .dados-do-comprador li {
    width: calc(100% - 100px);
    border-top: 3px solid #ad906e;
    text-align: center;
    padding: 30px 55px 0 55px;
    margin: 30px 50px;
    display: block;
    list-style: none;
  }
  .dados-do-comprador li:first-child {
    margin-left: auto;
  }
  .dados-do-comprador li .title {
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  .dados-do-comprador li p {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #85685b;
  }
  .dados-do-comprador a {
    width: 160px;
    display: block;
  }
}

@media (max-width: 639px) {
  #identificacao-sucesso {
    width: 320px;
  }
  #identificacao-sucesso .center-identification-success {
    width: 310px;
  }
  #identificacao-sucesso .center-identification-success h2 {
    font-size: 24px;
    padding: 18px 0;
  }
  #identificacao-sucesso .center-identification-success .left-success {
    width: 310px;
  }
  #identificacao-sucesso .center-identification-success .left-success .text {
    width: 310px;
    margin-bottom: 18px;
  }
  #identificacao-sucesso .center-identification-success .left-success .text .title {
    font-size: 13px;
    padding: 0 35px;
  }
  #identificacao-sucesso .center-identification-success .left-success .text p {
    font-size: 13px;
    margin-bottom: 3px;
    padding: 0 35px;
  }
  #identificacao-sucesso .center-identification-success .left-success .text a {
    width: 185px;
    margin-top: 15px;
  }
  #identificacao-sucesso .center-identification-success .left-success .text span {
    margin-top: 3px;
    font-size: 7px;
  }
  .dados-do-comprador {
    width: 310px;
    padding: 0 0 20px 0;
  }
  .dados-do-comprador li {
    width: calc(100% - 50px);
    border-top: 2px solid #ad906e;
    padding: 15px 27px 0 27px;
    margin: 15px 25px;
  }
  .dados-do-comprador li .title {
    font-size: 13px;
    margin-bottom: 5px;
  }
  .dados-do-comprador li p {
    font-size: 13px;
  }
  .dados-do-comprador a {
    width: 80px;
  }
}

#informacao-pedido {
  background: #f1e8e0;
  padding-bottom: 40px;
}
#informacao-pedido .center-information-order {
  width: 580px;
  margin: auto;
}
#informacao-pedido .center-information-order h2 {
  margin: 0;
  padding: 35px 0 0 0;
  font-size: 20px;
  text-transform: uppercase;
}
#informacao-pedido .center-information-order .left-order {
  width: 262px;
  display: inline-block;
  vertical-align: top;
  margin-top: 26px;
}
#informacao-pedido .center-information-order .left-order h3 {
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
  text-transform: uppercase;
}
#informacao-pedido .center-information-order .left-order ul {
  padding: 10px 15px 10px 15px;
  margin: 0 0 0 0;
  background: #ded5cd;
}
#informacao-pedido .center-information-order .left-order ul li {
  list-style: none;
  margin-bottom: 15px;
}
#informacao-pedido .center-information-order .left-order ul li .title {
  text-transform: uppercase;
  font-family: 'martelheavy';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
#informacao-pedido .center-information-order .left-order ul li .description {
  font-family: 'martelregular';
  font-weight: normal;
  font-size: 12px;
  color: #85685b;
  margin-bottom: 0;
  line-height: 17px;
}
@media (min-width: 992px) and (max-width: 1260px) {
  #informacao-pedido {
    padding-bottom: 31px;
  }
  #informacao-pedido .center-information-order {
    width: 450px;
  }
  #informacao-pedido .center-information-order h2 {
    padding: 27px 0 0 0;
    font-size: 16px;
  }
  #informacao-pedido .center-information-order .left-order {
    width: 203px;
    margin-top: 20px;
  }
  #informacao-pedido .center-information-order .left-order h3 {
    font-size: 11px;
  }
  #informacao-pedido .center-information-order .left-order ul {
    padding: 8px 12px;
  }
  #informacao-pedido .center-information-order .left-order ul li {
    margin-bottom: 12px;
  }
  #informacao-pedido .center-information-order .left-order ul li .title {
    font-size: 9px;
  }
  #informacao-pedido .center-information-order .left-order ul li .description {
    font-size: 9px;
    line-height: 13px;
  }
}
@media (max-width: 991px) {
  #informacao-pedido {
    background: #fff;
    padding-bottom: 40px;
  }
  #informacao-pedido .title {
    width: 100%;
  }
  #informacao-pedido .title h2 {
    text-align: center;
    padding: 40px 64px;
  }
  #informacao-pedido .center-information-order {
    width: 620px;
    margin: auto;
    background: #fff;
  }
  #informacao-pedido .center-information-order .left-order {
    background: #f1e8e0;
    width: 100%;
    display: block;
    margin: 30px 0 10px 0;
  }
  #informacao-pedido .center-information-order .left-order ul {
    padding: 30px;
    margin: 0 0 0 0;
    background: #ded5cd;
  }
  #informacao-pedido .center-information-order .left-order ul li {
    text-align: center;
    border-top: 2px solid #ad906e;
    padding: 20px 0;
  }
  #informacao-pedido .center-information-order .left-order ul li:first-child {
    border-top: 0;
  }
  #informacao-pedido .center-information-order .left-order ul li .title {
    text-transform: uppercase;
    font-family: 'martelheavy';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #informacao-pedido .center-information-order .left-order ul li .description {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 26px;
    color: #85685b;
    line-height: 30px;
  }
}
@media (max-width: 639px) {
  #informacao-pedido {
    padding-bottom: 20px;
  }
  #informacao-pedido .title h2 {
    padding: 20px 32px;
  }
  #informacao-pedido .center-information-order {
    width: 310px;
  }
  #informacao-pedido .center-information-order .left-order {
    margin: 0 0 5px 0;
  }
  #informacao-pedido .center-information-order .left-order ul {
    padding: 15px;
  }
  #informacao-pedido .center-information-order .left-order ul li {
    border-top: 1px solid #ad906e;
    padding: 10px 0;
  }
  #informacao-pedido .center-information-order .left-order ul li .title {
    font-size: 13px;
  }
  #informacao-pedido .center-information-order .left-order ul li .description {
    font-size: 13px;
    line-height: 15px;
  }
}

#right-success {
  margin-top: 56px;
  width: 300px;
  display: inline-block;
  vertical-align: top;
}
#right-success ul {
  padding: 15px 0 0 30px;
  margin-bottom: 0;
}
#right-success ul li {
  list-style: none;
  border-bottom: 1px solid #c8b59e;
  padding: 0 0 15px 0;
  margin-top: 20px;
}
#right-success ul li:first-child {
  margin-top: 0;
}
#right-success ul .img {
  display: inline-block;
  vertical-align: top;
  width: 80px;
  height: 58px;
}
#right-success ul .text {
  display: inline-block;
  vertical-align: top;
  width: 158px;
  margin-left: 20px;
}
#right-success ul .text p {
  margin-bottom: 5px;
}
#right-success ul .text .title {
  line-height: 14px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #85685b;
}
#right-success ul .text .amount {
  line-height: 13px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
#right-success ul .text .title-pink {
  text-transform: uppercase;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #ed19d7;
}
#right-success ul .text .description {
  line-height: 13px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #85685b;
}
#right-success ul .text .unit {
  line-height: 13px;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 10px;
  color: #85685b;
}
#right-success ul .text .value {
  text-transform: uppercase;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
#right-success .freight {
  padding: 0 0 0 30px;
}
#right-success .freight .freight-value {
  height: 50px;
}
#right-success .freight .freight-value .text {
  float: left;
  line-height: 50px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
#right-success .freight .freight-value .price {
  float: right;
  margin-bottom: 0;
  line-height: 50px;
  text-transform: uppercase;
  font-family: 'martelbold';
  font-weight: normal;
  font-size: 12px;
  color: #4f2c1e;
}
#right-success .freight .total {
  height: 50px;
  border-bottom: 1px solid #c8b59e;
  border-top: 1px solid #c8b59e;
}
#right-success .freight .total .text {
  float: left;
  line-height: 50px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: 'martelheavy';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
}
#right-success .freight .total .price {
  float: right;
  line-height: 50px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: 'martelheavy';
  font-weight: normal;
  font-size: 14px;
  color: #4f2c1e;
}
#right-success {
  margin-top: 43px;
  width: 232px;
}
#right-success ul {
  padding: 12px 0 0 23px;
}
#right-success ul li {
  padding: 0 0 12px 0;
  margin-top: 16px;
}
#right-success ul .img {
  width: 62px;
  height: 45px;
}
#right-success ul .img img {
  width: 100%;
}
#right-success ul .text {
  width: 123px;
  margin-left: 16px;
}
#right-success ul .text p {
  margin-bottom: 4px;
}
#right-success ul .text .title {
  line-height: 11px;
  font-size: 9px;
}
#right-success ul .text .amount {
  line-height: 10px;
  font-size: 9px;
}
#right-success ul .text .title-pink {
  font-size: 8px;
}
#right-success ul .text .description {
  line-height: 10px;
  font-size: 8px;
}
#right-success ul .text .unit {
  line-height: 10px;
  font-size: 8px;
}
#right-success ul .text .value {
  font-size: 9px;
}
#right-success .freight {
  padding: 0 0 0 23px;
}
#right-success .freight .freight-value {
  height: 39px;
}
#right-success .freight .freight-value .text {
  line-height: 39px;
  font-size: 9px;
}
#right-success .freight .freight-value .price {
  line-height: 39px;
  font-size: 9px;
}
#right-success .freight .total {
  height: 39px;
}
#right-success .freight .total .text {
  line-height: 39px;
  font-size: 11px;
}
#right-success .freight .total .price {
  line-height: 39px;
  font-size: 11px;
}

@media (max-width: 991px) {
  #right-success {
    margin: 0 0 0px 0;
    width: 100%;
    display: block;
    background: #f1e8e0;
  }
  #right-success ul {
    padding: 15px 0 0 0px;
    margin-bottom: 0;
  }
  #right-success ul li {
    padding: 30px 20px 30px 20px;
    margin: 0;
    border-bottom: 2px solid #ad906e;
  }
  #right-success ul .img {
    width: 273px;
    height: 199px;
  }
  #right-success ul .text {
    width: 269px;
  }
  #right-success ul .text .title {
    line-height: 26px;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #85685b;
  }
  #right-success ul .text .amount {
    line-height: 16px;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 14px;
    color: #4f2c1e;
  }
  #right-success ul .text .title-pink {
    text-transform: uppercase;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 12px;
    color: #4f2c1e;
  }
  #right-success ul .text .description {
    line-height: 14px;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 12px;
    color: #85685b;
  }
  #right-success ul .text .unit {
    line-height: 16px;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 14px;
    color: #85685b;
  }
  #right-success ul .text .value {
    text-transform: uppercase;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #right-success .freight {
    padding: 0 0 0 0px;
  }
  #right-success .freight .freight-value {
    height: 90px;
  }
  #right-success .freight .freight-value .text {
    line-height: 90px;
    margin-left: 10px;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #right-success .freight .freight-value .price {
    line-height: 90px;
    margin-right: 10px;
    font-family: 'martelbold';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #right-success .freight .total {
    height: 90px;
    border-bottom: 1px solid #c8b59e;
    border-top: 1px solid #c8b59e;
  }
  #right-success .freight .total .text {
    line-height: 90px;
    margin-left: 10px;
    font-family: 'martelheavy';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
  #right-success .freight .total .price {
    line-height: 50px;
    margin-right: 10px;
    font-family: 'martelheavy';
    font-weight: normal;
    font-size: 26px;
    color: #4f2c1e;
  }
}

@media (max-width: 639px) {
  #right-success ul {
    padding: 7px 0 0 0;
    margin-bottom: 0;
  }
  #right-success ul li {
    padding: 15px 10px;
    border-bottom: 1px solid #ad906e;
  }
  #right-success ul .img {
    width: 137px;
    height: 100px;
  }
  #right-success ul .img img {
    width: 100%;
  }
  #right-success ul .text {
    width: 134px;
    margin-left: 10px;
  }
  #right-success ul .text .title {
    line-height: 13px;
    font-size: 13px;
  }
  #right-success ul .text .amount {
    line-height: 8px;
    font-size: 7px;
  }
  #right-success ul .text .title-pink {
    font-size: 6px;
  }
  #right-success ul .text .description {
    line-height: 7px;
    font-size: 6px;
  }
  #right-success ul .text .unit {
    line-height: 8px;
    font-size: 7px;
  }
  #right-success ul .text .value {
    font-size: 13px;
  }
  #right-success .freight .freight-value {
    height: 45px;
  }
  #right-success .freight .freight-value .text {
    line-height: 45px;
    margin-left: 5px;
    font-size: 13px;
  }
  #right-success .freight .freight-value .price {
    line-height: 45px;
    margin-right: 5px;
    font-size: 13px;
  }
  #right-success .freight .total {
    height: 45px;
  }
  #right-success .freight .total .text {
    line-height: 45px;
    margin-left: 5px;
    font-size: 13px;
  }
  #right-success .freight .total .price {
    line-height: 25px;
    margin-right: 5px;
    font-size: 13px;
  }
}